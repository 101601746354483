// todo fix loop extends
import '../components/common/UtilsDataProvider';
import AuthDataProvider from '../components/auth/AuthDataProvider';
import { User } from '../models/AdministrativeModels';
import Promise from 'bluebird';
import _ from 'lodash';
import RouterHelper from './RouterHelper';
import PubSub from 'pubsub-js';
import GlobalConst from '../utils/GlobalConst';
import WhiteLabelController from '../core/WhiteLabelController';
import Hotjar from '@hotjar/browser';

/*contains global Google Idenity Services API (`window.google.accounts`) */

class UserManager {

	user = User.fromJSON({});

	get authToken() {
		return localStorage.getItem('auth_token');
	}

	set authToken(token) {
		if (token) {
			localStorage.setItem('auth_token', token);
		} else {
			localStorage.removeItem('auth_token');
		}
	}

	clearAuthData() {
		this.authToken = null;
		this.user = User.fromJSON({});
		localStorage.removeItem('auth_token');
		localStorage.removeItem('auth_token_original_user');
		PubSub.publish('CLEAR_OAUTH');
	}

	setUserData(promise, loginAs=false) {
		return promise.then(data => {
			if(_.isEmpty(data)) {
				this.clearAuthData();
			} else {
				const isImpersonating = loginAs ? loginAs : this.isImpersonating;
				this.user = User.fromJSON({...data, isImpersonating});
				this.authToken = this.user.authToken;
				this.setHotjarIdentify();
				this.setPendoInitialize(this.user);
				if (data.is_active) {
					PubSub.publish('FETCH_OAUTH_DATA');
					PubSub.publish('TRIGGER_NOTIFICATIONS');
				}
			}
			PubSub.publish(GlobalConst.USER_CHANGED_MSG);
			return data;
		});
	}

	setUserDataAvailable(data, fromURL) {
		if(_.isEmpty(data)) {
			this.clearAuthData();
		} else {
			this.user = User.fromJSON(data);
			this.authToken = this.user.authToken;
			this.redirectOnLogin(fromURL);
			this.setHotjarIdentify();
			this.setPendoInitialize(this.user);
			if (data.is_active) {
				PubSub.publish('FETCH_OAUTH_DATA');
				PubSub.publish('TRIGGER_NOTIFICATIONS');
			}
		}
	}

	redirectOnLogin(from) {
		if(from && from !== '/') {
			RouterHelper.goTo(from);
		} else {
			RouterHelper.goToDefaultRoute();
		}
	}

	setUserAvatar(promise) {
		return promise.then(avatar => {
			this.user.profileImageUrl = avatar;
			PubSub.publish(GlobalConst.USER_CHANGED_MSG);
			return avatar;
		});
	}

	login(userName, password) {
		return AuthDataProvider.login(userName, password);
	}

	register(firstName, lastName, email, password, verifyPassword, company, annualAdSpend, userType, region, country) {
		return this.setUserData(AuthDataProvider.register(User.toJSON({firstName, lastName, email, password, verifyPassword, company, annualAdSpend, userType, region, country})));
	}

	autoLogin() {
		if(!this.authToken) {
			return Promise.reject();
		}
		return this.setUserData(AuthDataProvider.autoLogin(this.authToken));
	}

	reloadCurrentUser() {
		return this.autoLogin();
	}

	updateCurrentUser(data) {
		return this.setUserData(AuthDataProvider.updateCurrentUser(User.toJSON(data)));
	}

	updateUserAvatar(blob) {
		return this.setUserAvatar(AuthDataProvider.updateUserAvatar(blob));
	}

	deleteUserAvatar() {
		return this.setUserAvatar(AuthDataProvider.deleteUserAvatar());
	}

	changeUserPassword(currentPassword, newPassword) {
		return AuthDataProvider.changeUserPassword(currentPassword, newPassword).then(() => this.clearAuthData());
	}

	logout() {
		return AuthDataProvider.userLogout()
			.then(() => {
				this.clearAuthData();
				RouterHelper.goToLogin();
				PubSub.publish('CLEAR_TRIGGER_NOTIFICATIONS');
			})
			.catch(() => PubSub.publish(GlobalConst.SHOW_MODAL_MSG, {type: 3, error: 'Error logging out'}));
	}

	loginAsUser(user) {
		if (_.isEmpty(localStorage.getItem('auth_token_original_user'))) localStorage.setItem('auth_token_original_user', this.user.authToken);
		return this.setUserData(AuthDataProvider.loginAsUser(user.id), true);
	}

	loginAsOriginalUser() {
		const originalUserID = localStorage.getItem('auth_token_original_user');
		localStorage.removeItem('auth_token_original_user');
		return this.setUserData(AuthDataProvider.autoLogin(originalUserID))
			.then(() => RouterHelper.goToUserCabinet())
			.then(() => PubSub.publish('UPDATE_USERINFO'));
	}

	changePassword(email, token, password) {
		return AuthDataProvider.changePassword(email, token, password);
	}

	requestPasswordReset(email) {
		return AuthDataProvider.resetPassword(email);
	}

	authByGoogle() {
	    const client = window.google.accounts.oauth2.initCodeClient({
            client_id: this.clientId,
            scope: 'profile email https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/youtube.readonly',
            callback: ''
        });
        const response = new Promise((resolve, reject) => {
            client.callback = (response) => {
                if (response.error) {
                    reject('Authentication failed');
                }
                resolve({code: response.code});
            };
            client.requestCode();
        });
        return response;
	}

	authByGoogleAdWords() {
	    const client = window.google.accounts.oauth2.initCodeClient({
            client_id: this.clientId,
            scope: 'https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email',
            callback: ''
        });
        const response = new Promise((resolve, reject) => {
            client.callback = (response) => {
                if (response.error) {
                    reject('Authentication failed');
                }
                resolve({code: response.code});
            };
            client.requestCode();
        });
        return response;
	}

	authByDV360() {
	    const client = window.google.accounts.oauth2.initCodeClient({
            client_id: this.clientId,
            scope: 'https://www.googleapis.com/auth/display-video https://www.googleapis.com/auth/doubleclickbidmanager https://www.googleapis.com/auth/userinfo.email',
            callback: ''
        });
        const response = new Promise((resolve, reject) => {
            client.callback = (response) => {
                if (response.error) {
                    reject('Authentication failed');
                }
                resolve({code: response.code});
            };
            client.requestCode();
        });
        return response;
	}

	get isAuthorized() {
		return !_.isEmpty(this.authToken);
	}

	get isImpersonating() {
		const currentAuthToken = localStorage.getItem('auth_token');
		const originalAuthToken = localStorage.getItem('auth_token_original_user');
		if (_.isEmpty(originalAuthToken)) return false;
		if (currentAuthToken === originalAuthToken) return false;
		return true;
	}

	companyName() {
		return WhiteLabelController.siteAssets.name || '';
	}

	get defaultUserIcon() {
		return 'https://viewiq-ui-assets.s3.amazonaws.com/common/default_profile_icon.png';
	}

	get defaultVideoIcon() {
		return 'https://viewiq-ui-assets.s3.amazonaws.com/common/default_video_icon.png';
	}

	get clientId() {
		return process.env.NODE_ENV == 'production' && window.location.host.search(/^rc.(\w+\.)?viewiq\.com$/) === -1 ?
		'832846444492-5slgtqn6b05a1pi62mlom374ou2no9re.apps.googleusercontent.com'
		:'832846444492-dc1nft9jq4ocgmb4ri0v07h279hmmvr6.apps.googleusercontent.com'
	}

	setHotjarIdentify() {
		const userData = {
			internal: _.includes(this.user.email, '@channelfactory.com'),
			type: this.user.userType
		};
		Hotjar.identify(this.user.id, userData);
	}

	setPendoInitialize(user) {
		window.pendo.initialize({
			visitor: {
				id: user?.id || 'N/A'
			}
		});
	}

}

const manager = new UserManager();
export default manager;
