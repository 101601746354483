import _ from 'lodash';
import { normalizeDataWithValueKey } from '../core/chartsDataMappers';
import dateUtil from '../utils/dates';
import dates from '../utils/dates';
import { formatBigNumber } from '../utils/numbers';

export class Segment {

	static TYPES = {
		VIDEO: 'video',
		CHANNEL: 'channel',
		KEYWORD: 'keyword'
	};

	static fromJSON(json) {
		const params = { ...json.params };
		const ctl_params = { ...json.ctl_params };
		let type = Segment.TYPES.KEYWORD;
		if (json.segment_type == 0 || json.segment_type == '0' || json.segment_type == 'video') type = 'video';
		else if (json.segment_type == 1 || json.segment_type == '1' || json.segment_type == 'channel') type = 'channel';

		let thumbnailURL = json.thumbnail_image_url;

		let getStatistics = json => {
			const values = { ...json.statistics };
			switch (type) {
				case Segment.TYPES.CHANNEL:
					return {
						channelsCount: values.items_count,
						engageRate: values.engage_rate,
						subscribersCount: values.subscribers,
						subscribersPerChannel: values.subscribers_per_channel,
						topChannels: values.top_three_items ? _.map(json.statistics.top_three_items, channel => ({
							id: channel.id,
							image: channel.image_url,
							title: channel.title
						})) : [],
						videosCount: values.videos_count,
						viewsPerChannel: values.views_per_channel,
						ctr: values.ctr,
						ctrV: values.ctr_v,
						videoViewRate: values.video_view_rate,
						averageCPV: values.average_cpv,
						itemsCount: values.items_count,
						views: values.views,
						likes: values.likes,
						dislikes: values.dislikes,
						sentiment: values.sentiment,
						auditedVideos: values.audited_videos || values.videos_scored,
						monthlySubscribers: values.last_30day_subscribers,
						monthlyViews: values.last_30day_views,
						avgScore: values.average_brand_safety_score,
						vettingStatus: {
							percentage: values.vetted_items_count/values.items_count*100,
							vettedItems: values.vetted_items_count,
							safeItems: values.safe_items_count,
							unSafeItems: values.unsafe_items_count,
							suitableItems: values.suitable_items_count,
							unSuitableItems: values.unsuitable_items_count
						},
						videoExclusionFilename: values.video_exclusion_filename
					};
				case Segment.TYPES.VIDEO:
					return {
						engageRate: values.engage_rate,
						thirtyDaysViewsCount: values.thirty_days_views_count,
						topVideos: values.top_three_items ? _.map(values.top_three_items, video => ({
							id: video.id,
							image: video.image_url,
							title: video.title
						})) : [],
						videosCount: values.items_count,
						views: values.views,
						viewsPerVideo: values.views_per_video,
						ctr: values.ctr,
						ctrV: values.ctr_v,
						videoViewRate: values.video_view_rate,
						averageCPV: values.average_cpv,
						itemsCount: values.items_count,
						likes: values.likes,
						dislikes: values.dislikes,
						sentiment: values.sentiment,
						monthlyViews: values.last_30day_views,
						avgScore: values.average_brand_safety_score,
						vettingStatus: {
							percentage: values.vetted_items_count/values.items_count*100,
							vettedItems: values.vetted_items_count,
							safeItems: values.safe_items_count,
							unSafeItems: values.unsafe_items_count,
							suitableItems: values.suitable_items_count,
							unSuitableItems: values.unsuitable_items_count
						},
						videoExclusionFilename: values.video_exclusion_filename
					};
			}
			return {};
		};

		let getMiniDash = json => {
			switch (type) {
				case Segment.TYPES.CHANNEL:
					return json.mini_dash_data && {
							keywords: _.map(json.mini_dash_data.keywords, v => ({word: v.keyword, value: v.rate})),
							firstLineChart: normalizeDataWithValueKey(json.mini_dash_data.views_chart_data, 'views_count', 'date'),
							secondLineChart: normalizeDataWithValueKey(json.mini_dash_data.subscribers_chart_data, 'subscribers_count', 'date')
						};
				case Segment.TYPES.VIDEO:
					return json.mini_dash_data && {
							keywords: _.map(json.mini_dash_data.keywords, v => ({word: v.keyword, value: v.rate})),
							firstLineChart: normalizeDataWithValueKey(json.mini_dash_data.views_chart_data, 'video_views_count', 'date'),
							secondLineChart: normalizeDataWithValueKey(json.mini_dash_data.views_per_video_chart_data, 'views_per_video', 'date')
						};
			}
		};

		const generateMinMaxString = str => {
			const strSplit = str.split(', ');
			if (!_.isEmpty(strSplit[0]) && !_.isEmpty(strSplit[1])) return ({min: strSplit[0], max: strSplit[1], val: `${formatBigNumber(strSplit[0], 3)} to ${formatBigNumber(strSplit[1], 3)}`});
			else if (!_.isEmpty(strSplit[0], 3) && _.isEmpty(strSplit[1])) return ({min: strSplit[0], max: null, val: `min ${formatBigNumber(strSplit[0], 3)}`});
			else if (_.isEmpty(strSplit[0]) && !_.isEmpty(strSplit[1])) return ({min: null, max: strSplit[1], val: `max ${formatBigNumber(strSplit[1], 3)}`});
		}

		return _.extend(new this(), {
			ctlSynced: json.ctl_is_synced,
			category: json.category,
			createdAt: dateUtil.dateToLocalZone(json.created_at),
			updatedAt: dateUtil.dateToLocalZone(json.updated_at),
			id: json.id || json.template_id,
			isEditable: json.is_editable,
			miniDash: getMiniDash(json),
			owner: json.owner,
			type: type,
			mainCategory: json.main_category,
			collaboratorsEmails: json.shared_with,
			pendingUpdates: json.pending_updates,
			thumbnailURL: thumbnailURL,
			listType: json.list_type,
			pending: json.pending,
			segmentType: type,
			auditId: json.audit_id,
			isVettingComplete: json.is_vetting_complete,
			isFeatured: json.is_featured,
			isRegenerating: json.is_regenerating,
			dynRefreshStr: json.dynamic_refresh_str,
			lastDynRefresh: json.last_dynamic_refresh,
			dynRefreshSettings: json.dynamic_refresh_settings,
			isStandardFlagged: json.is_chrome_extension_standard_list,
			isShared: json.is_shared,
			isSharedWithOthers: json.is_shared_with_others,
			sharedBy: json.shared_by_email,
			featuredImageURL: json.featured_image_url,
			ownerId: json.owner_id,
			lastVetted: json.last_vetted_date,
			statistics: getStatistics(json),
			errorCreating: json.statistics?.error,
			//Filters
			templateId: ctl_params.template_id,
			buildType: ctl_params.build_type,
			title: json.title || json.template_title,
			sourceFile: json.source_name,
			ageGroups: ctl_params.age_groups,
			ageGroupsNA: ctl_params.age_groups_include_na,
			gender: ctl_params.gender,
			minimumViews: ctl_params.minimum_views,
			minimumViewsNA: ctl_params.minimum_views_include_na,
			minimumSubscribers: ctl_params.minimum_subscribers,
			minimumSubscribersNA: ctl_params.minimum_subscribers_include_na,
			minimumNumVideos: ctl_params.minimum_videos,
			minimumNumVideosNA: ctl_params.minimum_videos_include_na,
			contentCategories: ctl_params.content_categories,
			primaryCategories: ctl_params.relevant_primary_categories,
			excludedCategories: ctl_params.exclude_content_categories,
			primaryCategoriesThree: ctl_params.relevant_primary_categories_3,
			contentCategoriesThree: ctl_params.content_categories_3,
			excludedCategoriesThree: ctl_params.exclude_content_categories_3,
			sentiment: ctl_params.sentiment,
			languages: ctl_params.languages,
			languagesNA: ctl_params.languages_include_na,
			countries: ctl_params.countries,
			countriesNA: ctl_params.countries_include_na,
			scoreThreshold: ctl_params.score_threshold,
			garmSuitability: ctl_params.advanced_suitability_categories,
			contentType: ctl_params.content_type,
			contentQuality: ctl_params.content_quality,
			nlpTopicsInclude: ctl_params.nlp_topics,
			nlpUmbrellasInclude: ctl_params.nlp_umbrellas,
			includeAttributes: ctl_params.attributes,
			languageThreshold: ctl_params.language_threshold_percentage,
			nlpTopicsExclude: ctl_params.exclude_nlp_topics,
			exclusionFile: _.isEmpty(params.exclusion_file) ? null : params.exclusion_file,
			inclusionFile: _.isEmpty(params.inclusion_file) ? null : params.inclusion_file,
			inclusionHitThreshold: ctl_params.inclusion_hit_threshold,
			exclusionHitThreshold: ctl_params.exclusion_hit_threshold,
			viewRate: ctl_params.video_view_rate ? generateMinMaxString(ctl_params.video_view_rate) : {},
			to100Completion: ctl_params.video_quartile_100_rate ? generateMinMaxString(ctl_params.video_quartile_100_rate) : {},
			views30d: ctl_params.last_30day_views ? generateMinMaxString(ctl_params.last_30day_views) : {},
			avgCPV: ctl_params.average_cpv ? generateMinMaxString(ctl_params.average_cpv) : {},
			avgCPM: ctl_params.average_cpm ? generateMinMaxString(ctl_params.average_cpm) : {},
			ctrV: ctl_params.ctr_v ? generateMinMaxString(ctl_params.ctr_v) : {},
			ctr: ctl_params.ctr ? generateMinMaxString(ctl_params.ctr) : {},
			vettingStatus: ctl_params.vetting_status,
			minVideoDuration: _.isEmpty(ctl_params.minimum_duration) ? null : ctl_params.minimum_duration,
			maxVideoDuration: _.isEmpty(ctl_params.maximum_duration) ? null : ctl_params.maximum_duration,
			excludeMismatchedLanguages: ctl_params.mismatched_language,
			excludeNonLatin: ctl_params.exclude_non_latin_titles,
			includeUnknownAdsStats: ctl_params.ads_stats_include_na,
			vettedAfterDate: _.isEmpty(ctl_params.vetted_after) ? null : dates.formatToDatePicker(ctl_params.vetted_after),
			iasVerified: !_.isEmpty(ctl_params.ias_verified_date )? true : false,
			isMonetized: ctl_params.is_monetizable,
			lastUploadDate: _.isEmpty(ctl_params.last_upload_date) ? null : dates.formatToDatePicker(ctl_params.last_upload_date),
			comscoreSegments: ctl_params.comscore_segments,
			ethnicities: ctl_params.ethnicities,
			pillars: ctl_params.pillar_associations,
			madeForKidsThreshold: _.isNumber(ctl_params.made_for_kids_threshold_percentage) ? ctl_params.made_for_kids_threshold_percentage : 100,
			videoShortsType: _.isBoolean(ctl_params.is_youtube_short) ? ctl_params.is_youtube_short : null,
			channelShortsType: _.isString(ctl_params.filter_youtube_shorts_views) ? ctl_params.filter_youtube_shorts_views : null,
			channeShortsViews: _.isNumber(ctl_params.minimum_youtube_shorts_views) ? ctl_params.minimum_youtube_shorts_views : null,
			keyWordInclusion: json.ctl_params.client_custom_data?.inclusion_list_name ?
				{ id: json.ctl_params.client_custom_data.inclusion_list_id, name: json.ctl_params.client_custom_data.inclusion_list_name } :
				null,
			keyWordExclusion: json.ctl_params.client_custom_data?.exclusion_list_name ?
				{ id: json.ctl_params.client_custom_data.exclusion_list_id, name: json.ctl_params.client_custom_data.exclusion_list_name } :
				null,
			channelInclusion: json.ctl_params.client_custom_data?.channel_inclusion_list_name ?
				{ id: json.ctl_params.client_custom_data.channel_inclusion_list_id, name: json.ctl_params.client_custom_data.channel_inclusion_list_name } :
				null,
			channelExclusion: json.ctl_params.client_custom_data?.channel_exclusion_list_name ?
				{ id: json.ctl_params.client_custom_data.channel_exclusion_list_id, name: json.ctl_params.client_custom_data.channel_exclusion_list_name } :
				null,
			videoInclusion: json.ctl_params.client_custom_data?.video_inclusion_list_name ?
				{ id: json.ctl_params.client_custom_data.video_inclusion_list_id, name: json.ctl_params.client_custom_data.video_inclusion_list_name } :
				null,
			videoExclusion: json.ctl_params.client_custom_data?.video_exclusion_list_name ?
				{ id: json.ctl_params.client_custom_data.video_exclusion_list_id, name: json.ctl_params.client_custom_data.video_exclusion_list_name } :
				null
		});
	}

	static toJSON(data, type) {
		const result = {title: data.title};
		if(data.category) {
			result.category = data.category;
		}
		if (type) {
			const itemsMapper = items => {
				if(type === Segment.TYPES.KEYWORD) {
					return _.map(items, item => decodeURIComponent(item.id));
				}
				return _.map(items, 'id');
			};
			result['ids_to_delete'] = itemsMapper(data.itemsToRemove);
			result['ids_to_add'] = itemsMapper(data.itemsToAdd);
			result['ids_to_create'] = itemsMapper(data.itemsToCreate);
			result['filters'] = data.filters;
		}

		return result;
	}

	get isPrivate() {
		return this.category === 'private';
	}

}
